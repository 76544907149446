import  React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import './CardUsers.scss';
import {
	Card, 
	CardContent,
	Button,
	CardActions,
	CardMedia,
	TextField,
	Dialog,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	InputAdornment,
	IconButton,
	Icon,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import { useDispatch } from 'react-redux';
import { 
	createUser,
	deleteUser, 
	updateUser, 
	loadProfilePhoto 
} from "../../redux/actions/user-actions";
import ModalSubirImagen from "../ModalSubirImagen/ModalSubirImagen";
import { backend } from "../../utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  content: {
    flexGrow: 1,
		backgroundColor: 'white'
  },
});

const 	CardUsers = ({item, openModal, setOpenModal, callback, notification, isPartner}) => {
  const classes = useStyles();
	const [open, setOpen] = useState(openModal);
	const [showPassword, setShowPassword] = useState(false);
	const [showModalImage, setShowModalImage]= useState(false)
	const dispatch = useDispatch()
	const userCreate = async (member) => dispatch(createUser(member))
	const userDelete = async (id) => dispatch(deleteUser(id))
	const userUpdate = async (id, member) => dispatch(updateUser(id, member))
	const loadImage = async (data) => dispatch(loadProfilePhoto(data))
	const [fotoPerfil , setFotoPerfil] = useState('');	
	const [fileName , setFileName]=useState('');
	const [showInsurance, setShowInsurance] = useState(true);
	const user = useSelector((state) => state.User);


	useEffect(()=>{
		if(item){
			if(item?.rol === backend.rol.partner){
				setShowInsurance(true)
			}else{
				setShowInsurance(false)
			}
		}
	},[])

	async function onReceiveFile(file) {
		setFotoPerfil(file.b64);
		setFileName(file.name.replace(/\.[^/.]+$/, ''));
		setShowModalImage(false);
	}

	const saveFileS3 =async (id)=>{
		let data = {
				id,
				fileName: fileName,
				extension: "png",
				base64: fotoPerfil,
		}; 
		let res = await loadImage(data);
		if (res?.status === 200) {
				userUpdate(id, {url_photo: res.data.message});
		} else {
				console.log(res)	
		} 
}

	const validationSchema = Yup.object({
				name: Yup.string().required('Requerido'),
				user	: Yup.string().required('Requerido'),
				email: Yup.string().required('Requerido'),
        rol: Yup.string().required('Requerido'),
				password: Yup.string().required('Requerido'),
    })

	const formik = useFormik({
		initialValues: {
				name: item !== null ? item.name:'',
				user: item !== null ? item.user:'',
				email: item !== null ? item.email:'',
				rol: item !== null ? item.rol: backend.rol.partner,
				password: item !== null ? item.password: '',
				url_photo: item !== null ? item.url_photo: '',
				insurance_name: item  !== null ? item.insurance_name: user?.name,	
		},
		onSubmit:  async (values) => {
			let idUser=item?._id;
			if(item){
				await userUpdate(item._id,values)
				notification('Usuario actualizado correctamente!')
			}else{
				await userCreate(values).then((response)=>{
					idUser = response.data.message;
				})
				notification('Usuario creado correctamente !')
			}
			if(fotoPerfil !== '' ){
				await saveFileS3(idUser);
			}
			callback();
			handleClose();
		},
		validationSchema,
		validate: values => {
				
		}
})

  const handleChange = (e) => {
		const { name, value } = e.target;
    formik.setFieldValue(name, value)
		if(value === backend.rol.partner){
			setShowInsurance(true)
		}else{
			setShowInsurance(false)
		}
  };

	const handleDelete = async() => {
		await userDelete(item._id);
		callback();
		notification('Usuario eliminado correctamente !')
		handleClose();
  };


	const handleClose = () => {
    setOpen(false);
		setOpenModal(false);
  };

  return (
		<Dialog
		open={open}
		onClose={setOpenModal}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		align='center'
		maxWidth="lg"
	>
		 <form onSubmit={formik.handleSubmit}>


    <Card className="modal-container-user">
				<Grid container justify="flex-end" className="card-close">
					<Grid item xs={10}></Grid>
					<Grid item xs={2} align='right'>
						<CloseButton onClick={handleClose}>
									<CloseIcon className="fas fa-times" />
							</CloseButton>
					</Grid>
				</Grid>
			<CardMedia
				className='card-media'
				onClick={()=>{setShowModalImage(true)}}
      >
				 <img
          src={fotoPerfil !== '' ? fotoPerfil: item?.url_photo}
          alt={item?.user}
          style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: '150px' }}
        />
				{item?.url_photo ? "" : fotoPerfil === '' ? "Click para cargar imagen":"" }
			</CardMedia>
      <CardContent className={classes.content} >
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							label="Nombre"
							id="outlined-size-small"
							size="small"
							name='name'
							fullWidth
							className="mt-1"
							value={formik.values.name} 
							error={formik.touched.name && formik.errors.name ? true : false} 
							onChange={formik.handleChange} 
							onBlur={formik.handleBlur}
							helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
							/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Usuario"
							id="outlined-size-small"
							size="small"
							name='user'
							fullWidth
							className="mt-1"
							value={formik.values.user} 
							error={formik.touched.user && formik.errors.user ? true : false} 
							onChange={formik.handleChange} 
							onBlur={formik.handleBlur}
							helperText={formik.touched.user && formik.errors.user ? formik.errors.user : ''}
							/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Email"
							id="outlined-size-small"
							defaultValue={item?.email}
							size="small"
							name='email'
							fullWidth
							value={formik.values.email} 
							error={formik.touched.email && formik.errors.email ? true : false} 
							onChange={formik.handleChange} 
							onBlur={formik.handleBlur}
							helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Contraseña"
							id="outlined-size-small"
							defaultValue={item?.password}
							size="small"
							name="password"
							fullWidth
							value={formik.values.password} 
							error={formik.touched.password && formik.errors.password ? true : false} 
							onChange={formik.handleChange} 
							onBlur={formik.handleBlur}
							helperText={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
							type={showPassword ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
								<InputAdornment position="end">
										<IconButton
												aria-label="toggle password visibility"
												style={{color:'gray'}}
												onClick={()=>{setShowPassword(!showPassword)}}
												onMouseDown={()=>{setShowPassword(!showPassword)}}
												edge="end"
										>
										{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
										</IconButton>
								</InputAdornment>
								)
						}}
						/>
					</Grid>

					<Grid item xs={6}>
					<FormControl 
						fullWidth
						error={formik.touched.rol && formik.errors.rol ? true : false} 
					>
						<InputLabel id="demo-simple-select-label">Rol</InputLabel>
						{
							 !isPartner ? (
								<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										size="small"
										label="Rol"
										name="rol"
										onChange={handleChange}
										value={formik.values.rol} 
										onBlur={formik.handleBlur} 
									>
											<MenuItem value='admin'>administrador</MenuItem>
											<MenuItem value='aseguradora'>aseguradora</MenuItem>
											<MenuItem value='partner'>partner</MenuItem>
								</Select>

							 ):(
								
								<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										size="small"
										label="Rol"
										name="rol"
										onChange={handleChange}
										value={formik.values.rol} 
										onBlur={formik.handleBlur} 
									>
										
											<MenuItem value='partner'>partner</MenuItem>
								
								</Select>
							
							 )
						}
							
					
					</FormControl>
					</Grid>


{
	showInsurance && (
		<Grid item xs={6}>
						<TextField
							label="Nombre  Aseguradora"
							id="outlined-size-small"
							defaultValue={item?.insurance_name}
							size="small"
							name='insurance_name'
							fullWidth
							value={formik.values.insurance_name} 
							error={formik.touched.insurance_name && formik.errors.insurance_name ? true : false} 
							onChange={formik.handleChange} 
							onBlur={formik.handleBlur}
							helperText={formik.touched.insurance_name && formik.errors.insurance_name ? formik.errors.insurance_name : ''}
						/>
					</Grid>
	)
}
					
					
				</Grid>
      </CardContent>
      <CardActions className='card-action'>
				<Grid container spacing={2}>
				
				{
						item && (
							<Grid xs={6} className="mt-1">
									<Button 
									variant="contained" 
									color="secondary"
									onClick={handleDelete}
									>
										ELIMINAR
								</Button>
							</Grid>
						)
					}
					<Grid item xs={6}>
									<Button 
									variant="contained" 
									color="primary" 
									type="submit"
									>
										GUARDAR
								</Button>
					</Grid>
				
				</Grid>
				
				
      </CardActions>
    </Card>



		</form>
		{
			showModalImage &&
			(
				<ModalSubirImagen 
                        openModal={showModalImage} 
                        handleClose={() => setShowModalImage(false)}
                        callback={onReceiveFile} 
                    />
			)
		}
		</Dialog>
  );
};

export default CardUsers;
 
const CloseButton = styled(IconButton)`
    color: #FF0050 !important;
    width: 25px;
    height: 23px;
		margin: 5px;
`;

const CloseIcon = styled(Icon)`
    font-size: 1.0rem !important;
`;