import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from "react-global-configuration";
import { local, dev, prod } from "./config";

if(window.location.hostname === 'localhost') {
  config.set(dev, { freeze: false })
  config.set(prod, { freeze: false })
  config.set(local, { assign: true })
} else  {
  config.set(prod);
} 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
