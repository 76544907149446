import React, { useEffect, useState } from 'react';
import {Paper, useMediaQuery} from '@mui/material';
import {
    PagingState,
    /* DataTypeProvider, */
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material/styles';
import CardCustomer from '../CardCustomer/CardCustomer';

/* const CurrencyFormatter = ({ value }) => (
    <b style={{ color: 'blue' }}>
        {value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
    </b>
); */


/* const CurrencyTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
    />
); */


const classes = {
    tableStriped: `ticker-tableStriped`,
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    },
    }));

const TableComponent = props => (
    <StyledTable
      {...props}
      className={classes.tableStriped}
    />
  );

function TableCustomer({data , selected, notification}){

    const [openDetail , setOpenDetail]= useState(false);
    const [clickedCutomer, setClickedCustomer] = useState(null);

    useEffect(()=>{
       
    },[])

    const [columns] = useState([
        { name: 'name', title: 'Nombre' },
        { name: 'last_name', title: 'Apellido' },
        { name: 'email', title: 'Correo'},
        { name: 'phone', title: 'Telefono'},
        { name: 'serie', title: 'Serie'},
        { name: 'RUT', title: 'RUT'},
    ]);

  const [tableColumnExtensions] = useState([
        { columnName: 'name', width: 100 },
        { columnName: 'last_name', width: 100 },
    ]); 

    /* const [currencyColumns] = useState(['total']); */
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(10);

	

    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
                onDoubleClick={()=>{
                    setOpenDetail(true)
                    handleItemClick(row)
                }}
        />
    ); 

    const handleItemClick=(row)=>{
        setClickedCustomer(row)
    }

    return (
        <Paper style={{ position: 'relative' }}>
          <Grid
            rows={data}
            columns={columns}
          >
            {/* <CurrencyTypeProvider
                for={currencyColumns}
            /> */}
            <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
            />
            <Table
                tableComponent={TableComponent} 
              	/* columnExtensions={tableColumnExtensions} */
               rowComponent={TableRow}
            />
            <TableHeaderRow />
            <PagingPanel />
          </Grid>
          {
            openDetail && (
            <CardCustomer
                item={clickedCutomer}
                openModal={openDetail}
                setOpenModal= {setOpenDetail}
                notification={notification}
            />)
			}
        </Paper>
    );
}

export default TableCustomer;