
export const backend={
	rol:{
		admin: 'admin',
		insurance: 'aseguradora',
		partner: 'partner',
	},
	product:{
		accidentes: 'Accidentes Personales',
		oncologico: 'Oncologico',
		mascotas: 'Mascotas'
	}
};