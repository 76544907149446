import {Fragment, useEffect, useState} from 'react';
import "./Navbar.scss";
import {
    AppBar,
    Toolbar,
    Icon,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    }  from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from "react-redux";
import { backend } from '../../utils';

const drawerWidth = 165;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1, 
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {  
        marginLeft: drawerWidth,
        width: `100%`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
    },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        backgroundColor: '#FF0050',
    ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));


function Navbar(){
    const classes = useStyles();
    const history = useNavigate();
    const user = useSelector((state) => state.User);
    const [menu , setMenu]= useState([]);

    const menuAdmin =[
        {
            label:"Dashboard",
            path:"/home",
            iconColor: "secondary",
            icon: "fas fa-chart-bar",
            onClick: () => history("/home"),
        },
        {
            label:"Suscripciones",
            path:"/suscriptions",
            iconColor: "secondary",
            icon: "fas fa-file",
            onClick: () => history("/suscriptions"),
        },
        {
            label:"Asegurados",
            path:"/customers",
            iconColor: "secondary",
            icon: "fas fa-user",
            onClick: () => history("/customers"),
        },
        {
            label:"Partners",
            path:"/partners",
            iconColor: "secondary",
            icon: "fas fa-user-circle",
            onClick: () => history("/partners"),
        },  
        {
            label:"Usuarios",
            path:"/users",
            iconColor: "secondary",
            icon: "fas fa-lock",
            onClick: () => history("/users"),
        },
        {
            
            label:"Salir",
            path:"/",
            iconColor: "primary",
            icon: "fas fa-sign-out-alt",
            onClick: () => {loginOut()},
        },
    ]

    const menuInsurance =[
        {
            label:"Dashboard",
            path:"/home",
            iconColor: "secondary",
            icon: "fas fa-chart-bar",
            onClick: () => history("/home"),
        },
        {
            label:"Suscripciones",
            path:"/suscriptions",
            iconColor: "secondary",
            icon: "fas fa-file",
            onClick: () => history("/suscriptions"),
        },
        {
            label:"Asegurados",
            path:"/customers",
            iconColor: "secondary",
            icon: "fas fa-user",
            onClick: () => history("/customers"),
        },
        {
            label:"Partners",
            path:"/partners  ",
            iconColor: "secondary",
            icon: "fas fa-user-circle",
            onClick: () => history("/partners"),
        },
        {
            
            label:"Salir",
            path:"/",
            iconColor: "primary",
            icon: "fas fa-sign-out-alt",
            onClick: () => {loginOut()},
        },
    ]

    const menuPartner =[
        {
            label:"Dashboard",
            path:"/home",
            iconColor: "secondary",
            icon: "fas fa-chart-bar",
            onClick: () => history("/home"),
        },
        {
            label:"Suscripciones",
            path:"/suscriptions",
            iconColor: "secondary",
            icon: "fas fa-file",
            onClick: () => history("/suscriptions"),
        },
        {
            label:"Asegurados",
            path:"/customers",
            iconColor: "secondary",
            icon: "fas fa-user",
            onClick: () => history("/customers"),
        },
        {
            
            label:"Salir",
            path:"/",
            iconColor: "primary",
            icon: "fas fa-sign-out-alt",
            onClick: () => {loginOut()},
        },
    ]


    useEffect(()=>{
        const storedUser = JSON.parse(localStorage.getItem('userLogin'));
		if(storedUser){
            switch(storedUser?.rol){
                case backend.rol.admin:
                    setMenu(menuAdmin);
                    break;
                case backend.rol.insurance:
                    setMenu(menuInsurance);
                    break;
                case backend.rol.partner:
                        setMenu(menuPartner);
                        break;
                default:
                    break;
            }
		}else{
            history('/')
		}
    },[])

    const loginOut=()=>{
        localStorage.clear()
        history("/")
    }


    return(
        <Fragment>
            <Grid className={classes.root}>
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                        [classes.appBarShift]: true,
                        })}
                    >
                        <Toolbar style={{backgroundColor: '#0A0544', zIndex: 2}}>
                                <img src='https://embedx.io/blog/content/images/2022/09/EmbedX2@3x_3-2.png'  
                                    alt="logo" 
                                    width={150}
                                />
                                <Grid container direction="column" alignItems="flex-end">
                                    <Grid item >
                                        <Typography >
                                            {user?.user}
                                        </Typography>
                                        <Typography variant='caption'>
                                            {user?.rol}
                                        </Typography>
                                    </Grid>
                                </Grid>
                        </Toolbar>
                    </AppBar> 
                
                    <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                            [classes.drawerOpen]: true,
                            [classes.drawerClose]: !true,
                            })}
                            classes={{
                            paper: clsx({
                                [classes.drawerOpen]: true,
                                [classes.drawerClose]: !true,
                            }),
                            }}
                            style={{zIndex: 0}}
                        >
                        <Grid className={classes.toolbar}></Grid>
                        <List>
                            {
                                menu?.map(item=>(
                                    <ListItem button key={item.label}>
                                        <ListItemIcon   onClick={item.onClick}>
                                        <Icon
                                            color={item.iconColor}
                                            className={clsx(item.icon, "icon")}
                                        />
                                        </ListItemIcon>
                                        <ListItemText 
                                            style={{marginLeft:"-20px"}} 
                                            onClick={item.onClick}
                                        >
                                        <Typography style={{fontSize:"16px"}} >{item.label}</Typography>
                                        </ListItemText > 
                                    </ListItem>
                                    ))
                            }
                        </List>
                        <Typography className="sider-copy">by EmbedX.io 2023  v0.5</Typography>
                    </Drawer>
                    
                </Grid>
            

        </Fragment>
    );
}


export default Navbar;