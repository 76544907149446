import React, {useState} from "react";
import { Grid, Typography , TextField, 	IconButton, Button} from "@mui/material";
import { useNavigate } from "react-router-dom";
import './Login.scss'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { loginUser } from "../../redux/actions/user-actions";
import { getTokenApi } from "../../redux/actions/auth-actions";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PetSlider } from "../../components";


function Login (){

	let navegate = useNavigate();
	const [otp, setOtp] = useState('')
	const notifyError = (error) => toast.error(error);
	const dispatch = useDispatch();
	const login = async (user, pass, token) => dispatch(loginUser(user, pass, token))
	const getToken = async () => dispatch(getTokenApi())
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [username, setUsername] = useState("");


  const handleComplete = async (finalValue) => {
		setOtp(finalValue)
		await getToken()
		.then(async(data)=>{
			await login(username, finalValue, data?.data?.token)
						.then(async (user) => {
							if(user.data){
								if(user.status !== 200){
									notifyError('Acceso denegado ');
								}else{
									localStorage.setItem('userLogin', JSON.stringify(user.data));
									navegate('/home') 
								}
								
							}
							else{
								notifyError('NIP incorrecto');
							}
						})
		.catch((error)=>{
			notifyError(' Upps ocurrio un error');
			console.log('Error en login ', error)
		})
		}).catch((error)=>{
			notifyError(' Upps ocurrio un error');
			console.log('Error en login ', error)
		})

  }

	const pressEnter=(event)=> {
		if (event.keyCode === 13) {
			handleComplete( password);
		}
}




	return(
		<Grid >
			<Grid >

					<Grid container spacing={2}>
						
						<Grid item md={6} xs={12} style={{padding: '100px'}}>

										<Grid item xs={12} align='left'>
											<img 
												src='https://embedx.io/blog/content/images/2022/09/EmbedX2@3x_3-2.png' 
												alt="logologin" 
												width={300}
												/>
										</Grid>
										<Grid item xs={12}>
												<TextField
													label="Usuario"
													name='username'
													variant="outlined" 
													fullWidth
													className="mt-2"
													onChange={(event) => setUsername(event.target.value)}
													/>
									</Grid>
									<Grid item xs={12}>
											<TextField 
														helperText="" 
														label="Contraseña" 
														variant="outlined" 
														name="password" 
														fullWidth
														type={showPassword ? 'text' : 'password'}
														onChange={(event) => setPassword(event.target.value)}
														className="sia-input-outline-block mt-1" 
														color="primary"
														onKeyDown={(e) => pressEnter(e) }
														InputProps={{
																endAdornment: (
																<InputAdornment position="end">
																		<IconButton
																				aria-label="toggle password visibility"
																				style={{color:'gray'}}
																				onClick={()=>{setShowPassword(!showPassword)}}
																				onMouseDown={()=>{setShowPassword(!showPassword)}}
																				edge="end"
																		>
																		{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
																		</IconButton>
																</InputAdornment>
																)
														}}
												/>
									</Grid>
									<Grid item xs={11}></Grid>
										<Grid xs={6} className="mt-1">
															<Button 
															variant="contained" 
															color="primary"
															onClick={()=>{handleComplete(password)}}
															>
																Iniciar Sesión
														</Button>
										</Grid>

						</Grid>

						<Grid item md={6} xs={12}>
							<PetSlider />
						</Grid>
							

					</Grid>

			</Grid>
			<ToastContainer 
					position="top-center"
					autoClose={2000}
			/>
		</Grid>
	);

}

export default Login;