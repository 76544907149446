/* eslint-disable import/no-anonymous-default-export */
import { GET_TOKEN_API } from "../types";

const initialState = {
    tokenApi: "",
    userId: "",
    isLoggedIn: false,
};

export default function dataAuth(state = initialState, action) {
    switch (action.type) {
        case GET_TOKEN_API:
            return {
                ...state,
                tokenApi: action.payload.token
            };
        default:
            return state;
    }
}
