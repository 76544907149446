import React, { useState } from 'react';
import { AgChartsReact } from 'ag-charts-react';

// Chart Component
const MetricChartSales = () => {
  // Chart Options: Control & configure the chart
  const [chartOptions, setChartOptions] = useState({

    title: { text: 'Venta acumulado' },
    subtitle: { text: 'ultimos 6 meses ' },
    // Data: Data to be displayed in the chart
    data: [
        { month: 'Jul', valor: 100, ventas: 100 },
        { month: 'Ago', valor: 150, ventas: 150 },
        { month: 'Sep', valor: 350, ventas: 350 },
        { month: 'Oct', valor: 400, ventas: 400 },
        { month: 'Nov', valor: 280, ventas: 280 },
        { month: 'Dic', valor: 230, ventas: 230 },
    ],
    // Series: Defines which chart type and data to use
    series: [
        { type: 'bar', xKey: 'month', yKey: 'ventas' },
        { type: 'line', xKey: 'month', yKey: 'valor' }
      ],
  });

  return (
    // AgCharsReact component with options passed as prop
    <AgChartsReact options={chartOptions} />
  );
}

export default MetricChartSales;