import { 
	Grid, 
	Typography, 
	ListItem, 
	Avatar, 
	ListItemAvatar, 
	ListItemText  } from "@mui/material";
import './ListUsers.scss';
import React, { useState } from "react";
import CardUsers from "../CardUsers/CardUsers";

function ListUsers({ data, columns, updateData, notification }) {
	const [selected, setSelected] = useState(null)
	const [openDetail , setOpenDetail]= useState(false);

	const onClickItem=(item)=>{
		setSelected(item);
		setOpenDetail(true);
	}

  return (
    <Grid container spacing={3}>
      {
				data?.map((item) => (
        <Grid item xs={12 / columns} key={item.id}>
          <ListItem 
					divider 
					button  
					style={{ height: '70px'}}
					onClick={()=>{onClickItem(item)}}
					>
            <ListItemAvatar>
              <Avatar 
								alt={item.user} 
								src={item.url_photo}  
								sx={{width: 50, height:50}}
							/>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography 
								className= 'text-user-title'>
								{item.user}
								</Typography>
              }
              secondary={
								<Grid>
									<Typography className= 'text-user-rol'>{item.rol}</Typography>
									<Typography className= 'text-user-date'>{item.creation_date}</Typography>
								</Grid>
              }
							style={{margin:'5px'}}
            />
          </ListItem>
        </Grid>
      ))}
			{
					openDetail && (
					<CardUsers
						item={selected}
						openModal={openDetail}
						setOpenModal= {setOpenDetail}
						callback={updateData}
						notification={notification}
					/>)
			}
    </Grid>
  );
}


export default ListUsers;