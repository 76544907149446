import { 
	SUSCRIPTION_CREATE,
	SUSCRIPTION_DELETE,
	SUSCRIPTION_UPDATE,
	SUSCRIPTION_GET_ALL,
	SUSCRIPTION_GET
} from "../types"

const suscriptions = {}

export  default function dataSuscription(state=suscriptions, action){
	switch(action.type){
		case SUSCRIPTION_CREATE:
		case SUSCRIPTION_DELETE:
		case SUSCRIPTION_GET_ALL:
			case SUSCRIPTION_GET:
		case SUSCRIPTION_UPDATE:
			return action.payload;
		default:
			return state;
	}
}