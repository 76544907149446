import axios  from 'axios'
import config from "react-global-configuration";
import { 
	SUSCRIPTION_CREATE,
	SUSCRIPTION_UPDATE,
	SUSCRIPTION_DELETE,
	SUSCRIPTION_GET_ALL,
	SUSCRIPTION_GET
} from "../types"

export function createSuscription(suscription) {
	return async (dispatch) => {
			let res = await axios.post(`${config.get("urlAPI")}/suscription/create/`,suscription)
					.then((response) => {
							dispatch({ type: SUSCRIPTION_CREATE, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

export function updateSuscription(id, suscription) {
	return async (dispatch) => {
			let res = await axios.put(`${config.get("urlAPI")}/suscription/update/${id}`,suscription)
					.then((response) => {
							dispatch({ type: SUSCRIPTION_UPDATE, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
	}

	export function deleteSuscription(id) {
		return async (dispatch) => {
				let res = await axios.delete(`${config.get("urlAPI")}/suscription/delete/${id}`)
						.then((response) => {
								dispatch({ type: SUSCRIPTION_DELETE, payload: response.data })
								return response;
						})
						.catch((error) => {
								console.log(error);
								return error.response;
						})
				return res;
				}
}


export function getAllSuscriptions() {
	return async (dispatch) => {
			let res = await axios.get(`${config.get("urlAPI")}/suscription/get`)
					.then((response) => {
							dispatch({ type: SUSCRIPTION_GET_ALL, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

export function getSuscriptions(id) {
	return async (dispatch) => {
			let res = await axios.get(`${config.get("urlAPI")}/suscription/get/customer/${id}`)
					.then((response) => {
							dispatch({ type: SUSCRIPTION_GET, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

