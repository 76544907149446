import axios from "axios";
import config from "react-global-configuration";
import {  GET_TOKEN_API } from "../types";


// API
export function getTokenApi() {
    const configuration = { 
        headers: { "apikey": `${config.get("apiKey")}`, "clientid": `${config.get("clientId")}` } 
    };
    let response = {};
    return async (dispatch) => {
        try {
            response = await axios.get(`${config.get("urlAPI")}/security/token`, configuration);
            dispatch({ type: GET_TOKEN_API, payload: response.data });
        } catch (error) {
            console.log(error);
            response = error.response;
        }
        return response;
    }
}
