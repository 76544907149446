import React, {useEffect, useState} from "react";
import { Button, Grid, Typography } from "@mui/material";
import  Navbar  from '../../components/Navbar/Navbar';
import './Partner.scss'
import { ListUsers } from "../../components";
import {CardUsers} from "../../components";
import { getByInsurance } from '../../redux/actions/user-actions'
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { backend } from "../../utils";
import { useNavigate } from "react-router-dom";

function Partner (){
	const [openDetail , setOpenDetail]= useState(false);
	const dispatch = useDispatch()
	const getMembers = async (insurance) => dispatch(getByInsurance(insurance))
	const [members , setMembers] = useState([]);
	const notifySucess= (message) => toast.success(message);
	let navegate = useNavigate();

	useEffect(()=>{
		loadData()
	},[])

	const loadData =async()=>{
		const storedUser = JSON.parse(localStorage.getItem('userLogin'));
		if(storedUser){	
			switch(storedUser?.rol){
				case  backend.rol.admin:
					const res = await getMembers('*')
					setMembers(res.data) 	
				break;
				case  backend.rol.insurance:
					const response = await getMembers(storedUser?.name)
					setMembers(response.data) 
				break;
				default:
					break;
			}
		}else{
				navegate('/')
		}


	
		
		
	}


	const handleClickNewUser =()=>{
		setOpenDetail(true);
	}

	const notificationMessage=(message)=>{
		notifySucess(message)
	}

	return(
		<Grid className='main-container mt-4'>
			<Navbar />
			<Grid className="container-page">
				<Grid container spacing={2}>
					<Grid item xs={6} align='left'>
						<Typography variant="h6" className="mb-1">
								Partners
						</Typography>
					</Grid>
					<Grid item xs={6} align='right'>
						<Button 
							variant="contained" 
							className="mb-1" 
							size="small"
							onClick={handleClickNewUser}
						>
							Nuevo partner
						</Button>
					</Grid>
						{members.length>0  && <ListUsers 
						data={members} 
						columns={2} 
						updateData={loadData}
						notification={notificationMessage}
						/>}
				</Grid>
			</Grid>
			{
					openDetail && (
					<CardUsers
						item={null}
						openModal={openDetail}
						setOpenModal= {setOpenDetail}
						callback={loadData}
						notification={notificationMessage}
						isPartner={true}
					/>)
			}
			<ToastContainer 
					position="bottom-center"
					autoClose={1000}
			/>
		</Grid>
	);

}

export default Partner;