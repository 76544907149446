import { 
	CUSTOMER_CREATE,
	CUSTOMER_DELETE,
	CUSTOMER_UPDATE,
	CUSTOMER_GET_ALL
} from "../types"

const customers = {}

export  default function dataCustomer(state=customers, action){
	switch(action.type){
		case CUSTOMER_CREATE:
		case CUSTOMER_DELETE:
		case CUSTOMER_GET_ALL:
		case CUSTOMER_UPDATE:
			return action.payload;
		default:
			return state;
	}
}