import axios  from 'axios'
import config from "react-global-configuration";
import { 
	CUSTOMER_CREATE,
	CUSTOMER_UPDATE,
	CUSTOMER_DELETE,
	CUSTOMER_GET_ALL,
} from "../types"

export function createCustomer(customer) {
	return async (dispatch) => {
			let res = await axios.post(`${config.get("urlAPI")}/customer/create/`,customer)
					.then((response) => {
							dispatch({ type: CUSTOMER_CREATE, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

export function updateCustomer(id, customer) {
	return async (dispatch) => {
			let res = await axios.put(`${config.get("urlAPI")}/customer/update/${id}`,customer)
					.then((response) => {
							dispatch({ type: CUSTOMER_UPDATE, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
	}

	export function deleteCustomer(id) {
		return async (dispatch) => {
				let res = await axios.delete(`${config.get("urlAPI")}/customer/delete/${id}`)
						.then((response) => {
								dispatch({ type: CUSTOMER_DELETE, payload: response.data })
								return response;
						})
						.catch((error) => {
								console.log(error);
								return error.response;
						})
				return res;
				}
}


export function getAllCustomers() {
	return async (dispatch) => {
			let res = await axios.get(`${config.get("urlAPI")}/customer/get`)
					.then((response) => {
							dispatch({ type: CUSTOMER_GET_ALL, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

