import React from "react";
import "./PetSlider.scss";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function PetSlider() {
    return (
        <div className="slider-container">
            <AutoPlaySwipeableViews
                interval={5000}
                className="content-container"
            >
                <img
                    src="https://images.unsplash.com/photo-1631815584137-5f511f6f73ed?q=80&w=2786&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="image"
                />
                <img
                    src="https://images.unsplash.com/photo-1560743641-3914f2c45636?q=80&w=1851&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="image"
                />
                <img
                    src="https://images.unsplash.com/photo-1557446772-0985ac892643?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="image"
                />
            </AutoPlaySwipeableViews>
        </div>
    );
}

export default PetSlider;
