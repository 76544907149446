import axios  from 'axios'
import config from "react-global-configuration";
import { 
	USER_LOGIN,
	USER_CREATE,
	USER_UPDATE,
	USER_DELETE,
	USER_GET_ALL,
	USER_LOAD_IMAGE_PROFILE,
	USER_UPDATE_REDUX,
	USER_GET_BY_INSURANCE
} from "../types"


export function loginUser(user, pass, token) {
	const configuration = { headers: { "Authorization": `Bearer ${token}` } };
	return async (dispatch) => {
			let url = `${config.get("urlAPI")}/user/login/${user}/${pass}`;
			let res = await axios.get(url, configuration)
					.then((response) => {
							dispatch({ type: USER_LOGIN, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

export function createUser(user) {
	return async (dispatch) => {
			let res = await axios.post(`${config.get("urlAPI")}/user/create/`,user)
					.then((response) => {
							dispatch({ type: USER_CREATE, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

export function updateUser(id, user) {
	return async (dispatch) => {
			let res = await axios.put(`${config.get("urlAPI")}/user/update/${id}`,user)
					.then((response) => {
							dispatch({ type: USER_UPDATE, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
	}

	export function deleteUser(id) {
		return async (dispatch) => {
				let res = await axios.delete(`${config.get("urlAPI")}/user/delete/${id}`)
						.then((response) => {
								dispatch({ type: USER_DELETE, payload: response.data })
								return response;
						})
						.catch((error) => {
								console.log(error);
								return error.response;
						})
				return res;
				}
}


export function getAllUsers() {
	return async (dispatch) => {
			let res = await axios.get(`${config.get("urlAPI")}/user/get`)
					.then((response) => {
							dispatch({ type: USER_GET_ALL, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

export function getByInsurance(name) {
	return async (dispatch) => {
			let res = await axios.get(`${config.get("urlAPI")}/user/get/insurance/${name}`)
					.then((response) => {
							dispatch({ type: USER_GET_BY_INSURANCE, payload: response.data })
							return response;
					})
					.catch((error) => {
							console.log(error);
							return error.response;
					})
			return res;
			}
}

export function loadProfilePhoto(data) {
	let response = {};
	return async (dispatch) => {
			try {
					response = await axios.post(
							`${config.get("urlAPI")}/user/file/save`,
							data,
					);
					dispatch({ type: USER_LOAD_IMAGE_PROFILE, payload: response.data.message });
			} catch (error) {
					console.log(error);
					response = error.response;
			}
			return response;
	};
}


export function updateUserRedux(user) {
	return {
			type: USER_UPDATE_REDUX,
			payload: user
	}
}
