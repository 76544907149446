import { combineReducers } from "redux";
import dataUser from "./user-reducer";
import dataAuth from "./authReducer";
import dataCustomer from "./customer-reducer";
import dataSuscription from "./suscription-reducer";

export default combineReducers({
		User: dataUser,
		Auth: dataAuth,
		Customers: dataCustomer,
		Suscriptions: dataSuscription
});
