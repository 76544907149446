
import React, {useState, useEffect} from "react";
import { Grid, Typography } from "@mui/material";
import { Navbar , TableCustomer} from "../../components";
import './Customer.scss'
import { getAllCustomers } from "../../redux/actions/customers-actions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';


const Customer =()=>{

	const dispatch = useDispatch();
	
	const getCustomers = async () => dispatch(getAllCustomers())
	const [dataCustomers , setDataCustomers] = useState(null);
	const notifySucess= (message) => toast.success(message);


	const notificationMessage=(message)=>{
		notifySucess(message)
	}

	useEffect(()=>{
		loadData()
	},[])


	const loadData =async()=>{
		const res = await getCustomers()
		setDataCustomers(res.data);
	}


	return (
		<Grid className='main-container mt-2'>
		<Navbar />
		<Grid className="container-page"></Grid>
		<Grid style={{marginLeft: '180px'}}>
		<Typography variant="h5" className="mb-1"><b>Asegurados</b></Typography>
			{
					dataCustomers && (
						<TableCustomer
							data = {dataCustomers}
							notification={notificationMessage}
						/>
					)
			}
		</Grid>
			<ToastContainer 
					position="bottom-center"
					autoClose={1000}
			/>
		</Grid>
	)
}

export default Customer;