import { 
	USER_LOGIN,
	USER_CREATE,
	USER_DELETE,
	USER_UPDATE,
	USER_UPDATE_REDUX
} from "../types"

const user = {}

export  default function dataUser(state=user, action){
	switch(action.type){
		case USER_LOGIN:
		case USER_CREATE:
		case USER_DELETE:
		case USER_UPDATE_REDUX:
		case USER_UPDATE:
			return action.payload;
		default:
			return state;
	}
}